import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "../../../atoms/components";
import {
  useRecipeCookMutation,
  useAssessmentSubmitV2Mutation,
  useResourceEndMutation,
} from "../../../generated/graphql";
import { Modal } from "../Modal";
import { TimeChancesPrompt } from "../TimeChancesPrompt";
import { AssessmentResultV2 } from "../../../pages/components/AssessmentViewerV2";
import { CourseBlockV2 } from "../../../pages/components/ViewResourceV2";
import { Link, useSearchParams } from "react-router-dom";
import { devLog } from "../../../App";

export interface AnswerRequiredObject {
  isAnswered: boolean;
  itemUuid: string;
}

export interface SubmitAssessmentV2Props {
  timeLimit: any;
  // isOpen: boolean;
  // setIsOpen: (data: any) => void;
  blockUuid: string;
  resourceUuid: string;
  formData: any;
  examTimeslotUuid: string;
  setStopTimer: (data: any) => void;
  setScore: (data: any) => void;
  setResults: (data: AssessmentResultV2) => void;
  setPassOnSubmit: (data: any) => void;
  setPassingMark: (data: any) => void;
  isRetakeOpen: boolean;
  setIsRetakeOpen: (data: any) => void;
  score: any;
  results: AssessmentResultV2;
  setSubmit: (data: any) => void;
  submitExam: boolean;
  courseUuid: string;
  scheduleUuid?: string;
  chances?: number | null;
  submissions?: number;
  maxScore?: number;
  totalScore?: number;
  submissionUuid?: string | null;
  autoPending?: boolean;
  courseBlock?: CourseBlockV2;
  requiredState?: AnswerRequiredObject[] | null;
  setIsRequiredToastOpen?: (isOpen: boolean) => void;
  submissionStatus?: string;
}

export function SubmitAssessmentV2({
  timeLimit,
  blockUuid,
  resourceUuid,
  formData,
  examTimeslotUuid,
  setStopTimer,
  setScore,
  setResults,
  isRetakeOpen,
  setIsRetakeOpen,
  score,
  results,
  submitExam,
  setSubmit,
  courseUuid,
  scheduleUuid = "",
  chances,
  submissions,
  maxScore,
  totalScore,
  setPassOnSubmit,
  setPassingMark,
  submissionUuid,
  autoPending,
  courseBlock,
  requiredState,
  setIsRequiredToastOpen,
  submissionStatus,
}: SubmitAssessmentV2Props) {
  const [submission, setSubmission] = useState({});
  const [submitStatus, setSubmitStatus] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  // Create A Submission Mutation

  const [
    submitAssessmentV2,
    { data: course, loading: courseLoading, error: courseError },
  ] = useAssessmentSubmitV2Mutation({
    variables: {
      submissionUuid: submissionUuid || "",
      updatedSubmission: submission,
      blockUuid: blockUuid || "",
      courseScheduleUuid: scheduleUuid || "",
    },
  });

  const [recipeCookMutateFunction, { loading: loadingRecipeCook }] =
    useRecipeCookMutation();

  const submit = () => {
    const responses: any[] = [];
    if (Object.keys(formData).length > 0) {
      for (const [key, value] of Object.entries(formData)) {
        const response =
          typeof value === "object"
            ? { item: key, choices: value }
            : value !== ""
            ? { item: key, long_input: value, short_input: value }
            : null;

        if (response !== null) responses.push(response);
      }
    }

    // New Submission final format
    const _submission = {
      status:
        submitStatus === "final" && autoPending ? "in_progress" : submitStatus,
      timeslot: examTimeslotUuid ? examTimeslotUuid : "",
      responses: responses,
    };

    submitAssessmentV2({
      variables: {
        blockUuid: blockUuid || "",
        submissionUuid: submissionUuid || "",
        updatedSubmission: _submission,
      },
      onCompleted: (res) => {
        devLog("Submission completed");
        if (
          res.assessmentSubmitUpdateV2?.courseBlockSubmissionResult?.status !==
            "draft" &&
          res.assessmentSubmitUpdateV2?.submissionResult?.status !== "draft"
        ) {
          devLog("Submission logged completed");
          // Burecipes mutation
          if (blockUuid) {
            devLog("Completed Resource");
            recipeCookMutateFunction({
              variables: {
                ingredient: {
                  recipe_name: "resource_in_course",
                  body: {
                    timestamp: new Date().toISOString(),
                    object: {
                      resource_id: resourceUuid,
                      block_id: blockUuid,
                      course_id: courseUuid,
                    },
                    verb: "completed",
                    context: { c_block_id: blockUuid, c_course_id: courseUuid },
                  },
                },
              },
              onCompleted: () => {
                window.scroll(0, 0);
              },
            });
          } else {
            devLog("Completed Resource");
            recipeCookMutateFunction({
              variables: {
                ingredient: {
                  recipe_name: "resource",
                  body: {
                    timestamp: new Date().toISOString(),
                    object: { resource_id: resourceUuid },
                    verb: "completed",
                    context: { c_resource_id: resourceUuid },
                  },
                },
              },
            });
          }
        }
      },
    }).then(({ data }) => {
      setResults({
        courseBlockSubmissionResult:
          data?.assessmentSubmitUpdateV2?.courseBlockSubmissionResult,
        submissionResult: data?.assessmentSubmitUpdateV2?.submissionResult,
      });
    });
    setSubmission(_submission);
    setIsOpen(false);
    setStopTimer(true);
    let status;
    _submission.responses.forEach((response) => {
      // IF response has no choices, auto pending
      // if (!response.hasOwnProperty("choices") ) {
      if (autoPending) {
        status = "pending";
      }
    });
    setScore({ resultStatus: status, maxScore: 0, totalScore: 0 });
  };

  useEffect(() => {
    if (results?.submissionResult && submitStatus !== "") {
      const totalScore = results?.submissionResult.totalScore || 0;
      const maxScore =
        results?.submissionResult.highestPossibleSubmissionScore || 0;
      setScore({
        totalScore: totalScore,
        maxScore: maxScore,
        resultStatus: score.resultStatus,
      });
      const status = Math.floor((totalScore / maxScore) * 100);
      if (score.resultStatus !== "pending") {
        if (status >= 50)
          setScore((values: any) => ({
            ...values,
            resultStatus: "passed",
            canRetake:
              chances && submissions ? chances - submissions !== 0 : true,
            canGoNext: true,
          }));
        else
          setScore((values: any) => ({
            ...values,
            resultStatus: "failed",
            canRetake:
              chances && submissions ? chances - submissions !== 0 : true,
            canGoNext: false,
          }));
      }
    }
    if (results?.courseBlockSubmissionResult && submitStatus !== "") {
      const totalScore = results?.courseBlockSubmissionResult.totalScore || 0;

      const maxScore =
        results?.courseBlockSubmissionResult.blockStatus?.scoreMax ||
        results.submissionResult?.highestPossibleSubmissionScore ||
        results.courseBlockSubmissionResult.highestPossibleSubmissionScore ||
        results.submissionResult?.highestPossibleSubmissionScore ||
        0;
      const passingMark = courseBlock?.referenceInfo?.customPassingMark || 50;
      const passOnSubmit = courseBlock?.referenceInfo?.passOnSubmit
        ? true
        : false;

      setPassingMark(passingMark);
      setPassOnSubmit(passOnSubmit);
      setScore({
        totalScore: totalScore,
        maxScore: maxScore,
        resultStatus: score.resultStatus,
      });
      const status = Math.ceil((totalScore / maxScore) * 100);
      if (score.resultStatus !== "pending") {
        if (status >= passingMark || totalScore >= maxScore)
          setScore((values: any) => ({
            ...values,
            resultStatus: "passed",
            canRetake:
              chances && submissions ? chances - submissions !== 0 : true,
            canGoNext: true,
          }));
        else
          setScore((values: any) => ({
            ...values,
            resultStatus: "failed",
            canRetake:
              chances && submissions ? chances - submissions !== 0 : true,
            canGoNext: false,
          }));
      } else {
        if (status >= passingMark || totalScore >= maxScore) {
          setScore((values: any) => ({
            ...values,
            canGoNext: true,
            canRetake:
              chances && submissions ? chances - submissions !== 0 : true,
          }));
        } else {
          setScore((values: any) => ({
            ...values,
            canGoNext: false,
            canRetake:
              chances && submissions ? chances - submissions !== 0 : true,
          }));
        }
      }
    }
  }, [submitStatus, results]);

  const [resourceEndMutation] = useResourceEndMutation({
    variables: {
      courseScheduleUuid: scheduleUuid,
      resourceUuid: resourceUuid,
      courseBlockUuid: blockUuid,
    },
  });

  // useEffect for submission, to ensure na mag change muna yung submitStatus bago mag submit. To avoid status: ""
  useEffect(() => {
    if (submitStatus !== "") {
      submit();
      if (!courseBlock?.requiredForCertificate && submitStatus === "final") {
        resourceEndMutation();
      }
    }
  }, [submitStatus]);

  // useEffect for timer, Auto submit pag nag true yung submit Exam from timer component. || Pag naubos time
  useEffect(() => {
    if (submitExam) {
      setSubmit(false);
      // submit();
      setSubmitStatus("final");
    }
  }, [submitExam]);

  // Backlink for back to course/screen
  const [searchParams] = useSearchParams();
  const parentScreenName = searchParams.get("from_screen");
  const searchValue = searchParams.get("search_value");
  return (
    <>
      {results?.submissionResult || results?.courseBlockSubmissionResult ? (
        submissionStatus === "final" &&
        score.resultStatus !== "passed" &&
        !courseBlock?.referenceInfo?.passOnSubmit ? (
          <>
            <div className="flex justify-center w-full mb-2">
              <Button
                className="w-24 mx-4 text-sm border border-primary-600 text-primary-600"
                color="primaryInverted"
                onClick={() => {
                  setIsRetakeOpen(true);
                }}
                disabled={
                  chances && submissions ? chances - submissions === 0 : false
                }
              >
                Retake
              </Button>
            </div>
            {chances && submissions && (
              <div className="flex justify-center w-full mb-4 text-sm text-primary-600">
                <b>{chances - submissions}</b>
                &nbsp;chance(s) left
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center w-full mb-2">
            <Link
              to={
                courseUuid
                  ? `/courseviewer?course_uuid=${courseUuid}&course_schedule_uuid=${scheduleUuid}&from_screen=${parentScreenName}`
                  : searchValue
                  ? `/screen/search?search_value=${searchValue}`
                  : `/screen/${parentScreenName}`
              }
            >
              <Button
                className="w-full mx-4 text-sm text-gray-100 border border-primary-600"
                color="primary"
              >
                {courseUuid ? "Return to Course" : "Return to Screen"}
              </Button>
            </Link>
          </div>
        )
      ) : (
        <div className="flex justify-center w-full">
          {!timeLimit ? (
            <div className="flex justify-center mb-8 mr-2">
              <Button
                color="secondary"
                onClick={() => {
                  setSubmitStatus("draft");
                }}
              >
                Save as draft
              </Button>
            </div>
          ) : (
            ""
          )}

          <div className="flex justify-center mb-8">
            <Button
              color="secondary"
              onClick={() => {
                if (
                  !requiredState?.some(
                    (item: AnswerRequiredObject) => item.isAnswered !== true
                  ) ||
                  requiredState.length === 0
                ) {
                  setIsOpen(true);
                } else {
                  if (setIsRequiredToastOpen) setIsRequiredToastOpen(true);
                }
              }}
            >
              submit
            </Button>
          </div>
        </div>
      )}

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        className="w-80"
      >
        <div className="text-center">
          <h1 className="py-2 text-2xl font-bold">Are you sure?</h1>
          <p>
            This action cannot be undone after <b>submitting your assessment</b>
          </p>
        </div>

        <div className="flex justify-between w-full p-4">
          <Button
            color="primary"
            onClick={() => {
              setSubmitStatus("final");
            }}
          >
            confirm
          </Button>
          <Button
            color="primaryInverted"
            className="border border-primary-500"
            onClick={() => setIsOpen(false)}
          >
            cancel
          </Button>
        </div>
      </Modal>

      <Modal
        className="w-9/12 px-8 py-4 bg-white sm:w-1/2 rounded-xl"
        isOpen={isRetakeOpen}
        onRequestClose={() => setIsRetakeOpen(false)}
      >
        <TimeChancesPrompt
          results={score.resultStatus}
          maxScore={maxScore}
          totalScore={totalScore}
          retake={isRetakeOpen}
          blockUuid={blockUuid}
          resourceUuid={resourceUuid}
          courseUuid={courseUuid}
          scheduleUuid={scheduleUuid}
          onCancel={() => setIsRetakeOpen(false)}
        />
      </Modal>
    </>
  );
}
